<template>
  <div class="grid-container">
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-10">
        <h1 class="pb-5">Add new client</h1>
      </div>
      <div class="cell small-8 medium-2">
        <router-link
          to="/clients/index/">
          <button class="button expanded">
            All Clients
          </button>
        </router-link>
      </div>
    </div>
    <div class="grid-x grid-padding-x">
      <div class="cell small-12 medium-8">
        <form @submit.prevent="save">
          <label>Name
            <input
              aria-describedby="nameHelpText"
              name="name"
              placeholder="name"
              type="text"
              v-model="client.name">
          </label>
          <p
            class="help-text"
            id="nameHelpText">
            Please enter the client's name
          </p>
          <label class="mt-3">Short Name
            <input
              aria-describedby="shortNameHelpText"
              name="shortName"
              placeholder="short name"
              type="text"
              v-model="client.shortname">
          </label>
          <p
            class="help-text"
            id="shortNameHelpText">
            Please enter a three letter short name for the client. This will be used in job numbers.
          </p>
          <label class="mt-3">Telephone
            <input
              aria-describedby="telephoneHelpText"
              name="telephone"
              placeholder="telephone"
              type="text"
              v-model="client.telephone">
          </label>
          <p
            class="help-text"
            id="telephoneHelpText">
            Please enter the clients main telephone number.
          </p>
          <label class="mt-3">Address Line One
            <input
              aria-describedby="addressLineOneHelpText"
              name="addressLineOne"
              placeholder="address line one"
              type="text"
              v-model="client.addressLineOne">
          </label>
          <p
            class="help-text"
            id="addressLineOneHelpText">
            Please enter the first line of the client's address.
          </p>
          <label class="mt-3">Address Line Two
            <input
              aria-describedby="addressLineTwoHelpText"
              name="addressLineTwo"
              placeholder="address line two"
              type="text"
              v-model="client.addressLineTwo">
          </label>
          <p
            class="help-text"
            id="addressLineTwoHelpText">
            Please enter the second line of the client's address.
          </p>
          <label class="mt-3">Address Line Three
            <input
              aria-describedby="addressLineThreeHelpText"
              name="addressLineTwo"
              placeholder="address line three"
              type="text"
              v-model="client.addressLineThree">
          </label>
          <p
            class="help-text"
            id="addressLineThreeHelpText">
            Please enter the third line of the client's address.
          </p>
          <label class="mt-3">Address Line Four
            <input
              aria-describedby="addressLineFourHelpText"
              name="addressLineFour"
              placeholder="address line four"
              type="text"
              v-model="client.addressLineFour">
          </label>
          <p
            class="help-text"
            id="addressLineFourHelpText">
            Please enter the fourth line of the client's address.
          </p>
          <label class="mt-3">Address Line Five
            <input
              aria-describedby="addressLineFiveHelpText"
              name="addressLineFive"
              placeholder="address line five"
              type="text"
              v-model="client.addressLineFive">
          </label>
          <p
            class="help-text"
            id="addressLineFiveHelpText">
            Please enter the fifth line of the client's address.
          </p>
          <label class="mt-3">Postcode
            <input
              aria-describedby="postcodeHelpText"
              name="postcode"
              placeholder="postcode"
              type="text"
              v-model="client.postcode">
          </label>
          <p
            class="help-text"
            id="postcodeHelpText">
            Please enter the client's postcode.
          </p>
          <label class="mt-3">Invoice address?
            <input
              aria-describedby="addInvoiceAddressHelpText"
              name="addInvoiceAddress"
              type="checkbox"
              v-model="addInvoiceAddress">
          </label>
          <p
            class="help-text"
            id="addInvoiceAddressHelpText">
            Do you want to add a separate invoice address?
          </p>
          <div v-if="addInvoiceAddress">
            <label class="mt-3">Invoice Address Line One
            <input
              aria-describedby="invoiceAddressLineOneHelpText"
              name="invoiceAddressLineOne"
              placeholder="invoice address line one"
              type="text"
              v-model="client.invoiceAddressLineOne">
            </label>
            <p
              class="help-text"
              id="invoiceAddressLineOneHelpText">
              Please enter the first line of the client's invoice address.
            </p>
            <label class="mt-3">Invoice Address Line Two
              <input
                aria-describedby="invoiceAddressLineTwoHelpText"
                name="invoiceAddressLineTwo"
                placeholder="invoice address line two"
                type="text"
                v-model="client.invoiceAddressLineTwo">
            </label>
            <p
              class="help-text"
              id="invoiceAddressLineTwoHelpText">
              Please enter the second line of the client's invoice address.
            </p>
            <label class="mt-3">Invoice Address Line Three
              <input
                aria-describedby="invoiceAddressLineThreeHelpText"
                name="invoiceAddressLineTwo"
                placeholder="invoice address line three"
                type="text"
                v-model="client.invoiceAddressLineThree">
            </label>
            <p
              class="help-text"
              id="invoiceAddressLineThreeHelpText">
              Please enter the third line of the client's invoice address.
            </p>
            <label class="mt-3">Invoice Address Line Four
              <input
                aria-describedby="invoiceAddressLineFourHelpText"
                name="invoiceAddressLineFour"
                placeholder="invoice address line four"
                type="text"
                v-model="client.invoiceAddressLineFour">
            </label>
            <p
              class="help-text"
              id="invoiceAddressLineFourHelpText">
              Please enter the fourth line of the client's invoice address.
            </p>
            <label class="mt-3">Invoice Address Line Five
              <input
                aria-describedby="invoiceAddressLineFiveHelpText"
                name="invoiceAddressLineFive"
                placeholder="invoice address line five"
                type="text"
                v-model="client.invoiceAddressLineFive">
            </label>
            <p
              class="help-text"
              id="invoiceAddressLineFiveHelpText">
              Please enter the fifth line of the client's invoice address.
            </p>
            <label class="mt-3">Invoice Postcode
              <input
                aria-describedby="invoicePostcodeHelpText"
                name="invoicePostcode"
                placeholder="invoice postcode"
                type="text"
                v-model="client.invoicePostcode">
            </label>
            <p
              class="help-text"
              id="invoicePostcodeHelpText">
              Please enter the client's invoice postcode.
            </p>
          </div>
          <label class="mt-3">Invoice Email
            <input
              aria-describedby="invoiceEmailHelpText"
              name="invoiceEmail"
              placeholder="invoice email"
              type="text"
              v-model="client.invoiceEmail">
          </label>
          <p
            class="help-text"
            id="invoiceEmailHelpText">
            Please enter the client's invoice email address.
          </p>
          <label class="mt-3">Invoice Currency
            <select
              aria-describedby="invoiceCurrencyHelpText"
              name="invoiceCurrency"
              placeholder="invoice currency"
              v-model="client.invoiceCurrency">
              <option value="GBP">GBP £</option>
              <option value="EUR">EUR €</option>
              <option value="USD">USD $</option>
            </select>
          </label>
          <p
            class="help-text"
            id="invoiceCurrencyHelpText">
            Please select the client's invoice currency.
          </p>
          <label class="mt-3">Add Veeva?
            <input
              aria-describedby="addVeevaHelpText"
              name="addVeeva"
              type="checkbox"
              v-model="addVeeva">
          </label>
          <p
            class="help-text"
            id="addVeevaHelpText">
            Do you want to add Veeva information?
          </p>
          <div v-if="addVeeva === true">
            <label class="mt-3">Veeva username
              <input
                aria-describedby="veevaUsernameHelpText"
                name="veevaUsername"
                placeholder="Veeva username"
                type="text"
                v-model="client.veevaUsername">
            </label>
            <p
              class="help-text"
              id="veevUsernameHelpText">
              Please enter the Veeva username.
            </p>
            <label class="mt-3">Veeva password
              <input
                aria-describedby="veevaPasswordHelpText"
                name="veevaPassword"
                placeholder="Veeva password"
                type="text"
                v-model="client.veevaPassword">
            </label>
            <p
              class="help-text"
              id="veevaPasswordHelpText">
              Please enter the Veeva password.
            </p>
            <label class="mt-3">Veeva security
              <input
                aria-describedby="veevaSecurityHelpText"
                name="veevaSecurity"
                placeholder="Veeva security"
                type="text"
                v-model="client.veevaSecurity">
            </label>
            <p
              class="help-text"
              id="veevaSecurityHelpText">
              Please enter the Veeva security.
            </p>
          </div>
          <label class="mt-3">Add brands now?
            <input
              aria-describedby="addBrandsHelpText"
              name="addBrands"
              type="checkbox"
              v-model="addBrands">
          </label>
          <p
            class="help-text"
            id="addBrandsHelpText">
            Do you want to add brands now?
          </p>
          <div class="pb-3" v-if="addBrands">
            <label class="mt-3">Brand name
              <input
                aria-describedby="brandNameHelpText"
                name="brandName"
                placeholder="Brand name"
                type="text"
                v-model="brand.name">
            </label>
            <p
              class="help-text"
              id="brandNameHelpText">
              Please enter the brand's name.
            </p>
            <label class="mt-3">Brand short name
              <input
                aria-describedby="brandShortNameHelpText"
                name="brandShortName"
                placeholder="Brand short name"
                type="text"
                v-model="brand.shortName">
            </label>
            <p
              class="help-text"
              id="brandShortNameHelpText">
              Please enter a three letter short name for the brand. This will be used in the job
              number.
            </p>
            <span @click="addAnotherBrand" class="button small">Add another brand</span>
          </div>
          <label class="mt-3">Add contacts now?
            <input
              aria-describedby="addContactsHelpText"
              name="addContacts"
              type="checkbox"
              v-model="addContacts">
          </label>
          <p
            class="help-text"
            id="addContactsHelpText">
            Do you want to add contacts now?
          </p>
          <div v-if="addContacts">
            <label class="mt-3">First name
              <input
                aria-describedby="firstNameHelpText"
                name="firstName"
                placeholder="first name"
                type="text"
                v-model="contact.first_name">
            </label>
            <p
              class="help-text"
              id="firstNameHelpText">
              Please enter the contact's first name.
            </p>
            <label class="mt-3">Last name
              <input
                aria-describedby="lastNameHelpText"
                name="lastName"
                placeholder="last name"
                type="text"
                v-model="contact.last_name">
            </label>
            <p
              class="help-text"
              id="lastNameHelpText">
              Please enter the contact's last name.
            </p>
            <label class="mt-3">Email
              <input
                aria-describedby="emailHelpText"
                name="email"
                placeholder="email"
                type="text"
                v-model="contact.email">
            </label>
            <p
              class="help-text"
              id="emailHelpText">
              Please enter the contact's email address.
            </p>
            <label class="mt-3">Telephone
              <input
                aria-describedby="telephoneHelpText"
                name="telephone"
                placeholder="telephone"
                type="text"
                v-model="contact.telephone">
            </label>
            <p
              class="help-text"
              id="telephoneHelpText">
              Please enter the contact's telephone number.
            </p>
            <label class="mt-3">Title
              <input
                aria-describedby="titleHelpText"
                name="title"
                placeholder="title"
                type="text"
                v-model="contact.title">
            </label>
            <p
              class="help-text"
              id="titleHelpText">
              Please enter the contact's job title.
            </p>
            <label class="mt-3">Type
              <input
                aria-describedby="typeHelpText"
                name="type"
                placeholder="type"
                type="text"
                v-model="contact.type">
            </label>
            <p
              class="help-text"
              id="typeHelpText">
              Please enter the type of contact, e.g. Legal, Medical, Marketing, Finance etc
            </p>
            <span @click="addAnotherContact" class="button small">Add another contact</span>
          </div>
          <label class="mt-3">Assign account handlers now?
            <input
              aria-describedby="assignAccountHandlersHelpText"
              name="assignAccountHandlers"
              type="checkbox"
              v-model="assignAccountHandlers">
          </label>
          <p
            class="help-text"
            id="addContactsHelpText">
            Do you want to assign account handlers now?
          </p>
          <div v-if="assignAccountHandlers">
            <select v-model="accountHandler">
              <option value="0">Please choose:</option>
              <option
                v-for="(handler, index) in accountHandlerList"
                :key="index"
                :value="handler.id">
                {{ handler.name }}
              </option>
            </select>
            <span @click="assignAnotherHandler" class="button small">Assign another</span>
          </div>
          <div class="pt-3">
            <button class="button">
              Save client
            </button>
          </div>
        </form>
      </div>
    </div>
    <Message
      v-if="showMessage === true"
      :messageText="message"
      :link="false"
      :linkText="false"
      @closeMessage="showMessage = false" />
  </div>
</template>

<script>
import Message from '@/components/messages/Message.vue';
import axios from '../../axios';

export default {
  name: 'ClientsCreate',
  components: {
    Message,
  },
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  data() {
    return {
      accountHandler: 0,
      accountHandlers: [],
      accountHandlerList: [],
      addBrands: false,
      addContacts: false,
      addInvoiceAddress: false,
      addVeeva: false,
      assignAccountHandlers: false,
      brand: {
        name: '',
        shortName: '',
      },
      brands: [],
      contact: {
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
        type: '',
        title: '',
      },
      contacts: [],
      client: {
        name: '',
        shortname: '',
        telephone: '',
        addressLineOne: '',
        addressLineTwo: '',
        addressLineThree: '',
        addressLineFour: '',
        addressLineFive: '',
        postcode: '',
        invoiceAddressLineOne: '',
        invoiceAddressLineTwo: '',
        invoiceAddressLineThree: '',
        invoiceAddressLineFour: '',
        invoiceAddressLineFive: '',
        invoicePostcode: '',
        invoiceEmail: '',
        invoiceCurrency: 'GBP',
        veevaUsername: '',
      },
      message: '',
      password: '',
      showMessage: false,
    };
  },
  methods: {
    addAnotherBrand() {
      this.brands.push(this.brand);
      this.brand = {
        name: '',
        shortName: '',
      };
    },
    addAnotherContact() {
      this.contacts.push(this.contact);
      this.contact = {
        first_name: '',
        last_name: '',
        email: '',
        telephone: '',
        type: '',
        title: '',
      };
    },
    assignAnotherHandler() {
      this.accountHandlers.push(this.accountHandler);
      this.accountHandler = 0;
    },
    save() {
      if (this.brand.name.length > 0) this.brands.push(this.brand);
      if (this.contact.first_name.length > 0) this.contacts.push(this.contact);
      if (this.accountHandler > 0) this.accountHandlers.push(this.accountHandler);
      const postData = {};
      postData.client = this.client;
      postData.contacts = this.contacts;
      postData.brands = this.brands;
      postData.accountHandlers = this.accountHandlers;
      axios.post(`/clients/create.json?token=${this.token}`, postData)
        .then((response) => {
          this.message = response.data.message;
          this.showMessage = true;
        })
        .catch((error) => {
          console.log(error);
          this.message = 'Sorry the account was not created. Please try again.';
          this.showMessage = true;
        });
    },
    loadAccountHandlers() {
      axios.get(`/users/getList.json?token=${this.token}`)
        .then((response) => {
          this.accountHandlerList = response.data.users;
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
  mounted() {
    setTimeout(() => {
      this.loadAccountHandlers();
    }, 100);
  },
};
</script>
